<template>
    <ValidationObserver
        v-if="current_user.is_admin && (wallSettings.userPost.value || wallSettings.advertPost.value)"
        tag="form"
        ref="observer"
        class="wall__item wall__new-post new-post js-without-double-block"
        @submit.prevent="onSubmit"
    >
        <div class="dashboard__block new-post__content">
            <div class="new-post__input">
                <router-link to="/auth/profile/">
                    <avatar :src="current_user.image_zoom_out"/>
                </router-link>

                <div class="new-post__inputs">
                    <!-- Тема объявления -->
                    <ValidationProvider v-if="isAdvert" :rules="`required|min_length:${limits.MIN_CHARS}`" v-slot="{errors}" mode="eager">
                        <input
                            v-model="post_title"
                            class="new-post__title"
                            placeholder="Тема объявления"
                            type="text"
                            maxlength="100"
                        />
                        <span class="v-error animated fadeInDown pl-15" v-if="errors.length">
                            {{errors[0]}}
                        </span>
                    </ValidationProvider>
                    <!-- Текст поста/объявления -->
                    <ValidationProvider :rules="`required|min_length:${limits.MIN_CHARS}`" v-slot="{errors}" mode="eager">
                        <textarea-autosize
                            @keyup.enter.ctrl.native="onSubmit"
                            @focus.native="isFormActive = true"
                            @blur.native="onBlurForm"
                            v-model="post_text"
                            class="new-post__text-input"
                            :placeholder="isAdvert ? 'Сообщение' : 'Что у вас нового?'"
                        />
                        <span class="v-error animated fadeInDown pl-15" v-if="errors.length">
                            {{errors[0]}}
                        </span>
                    </ValidationProvider>
                </div>
            </div>
            <ul v-if="imageList.length" class="new-post__img-list">
                <li class="new-post__img-item" v-for="img in imageList" :key="img.id" :style="{backgroundImage: `url(${img.src})`}">
                    <span class="new-post__img-delete" @click="onImgDelete(img.id)" title="Удалить"/>
                </li>
            </ul>
            <vue-progress-bar></vue-progress-bar>

            <div v-if="!isAdvert" class="new-post__attach">
                <!--<label for="id_attach-file" class="new-post__attach-item hidden">
                    <svg class="new-post__file-icon" width="22" height="25" viewBox="0 0 22 25" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.68329 21.4678C2.54916 19.6583 1.47176 15.6374 3.28125 12.5032L7.875 4.54663C8.11693 4.1276 8.652 3.98493 9.0702 4.22638C9.48854 4.46791 9.63252 5.00263 9.39059 5.42166L4.79684 13.3783C3.47032 15.6759 4.26003 18.6253 6.55832 19.9522C8.85661 21.2791 11.8057 20.4883 13.1322 18.1907L17.9448 9.85517C18.7891 8.39273 18.2863 6.51621 16.8239 5.67187C15.3613 4.82744 13.4848 5.33025 12.6404 6.7927L8.26536 14.3705C7.90359 14.9971 8.11902 15.8016 8.74576 16.1634C9.37251 16.5253 10.1769 16.3096 10.5387 15.683L14.6949 8.48413C14.9369 8.0651 15.4719 7.92243 15.8901 8.16388C16.3085 8.40541 16.4525 8.94013 16.2105 9.35916L12.0543 16.558C11.2099 18.0205 9.33327 18.5232 7.87082 17.6789C6.40837 16.8345 5.90542 14.9579 6.74977 13.4955L11.1248 5.91767C12.4513 3.62021 15.4005 2.82928 17.6989 4.15628C19.9972 5.4832 20.7868 8.43274 19.4604 10.7302L14.6478 19.0657C12.8383 22.1999 8.81743 23.2773 5.68329 21.4678Z"
                            fill="#DBDEE9"/>
                    </svg>
                </label>-->
                <a
                    href="javascript:void(0)"
                    @click="isVoting = !isVoting"
                    class="new-post__attach-item new-post__attach-item--vote"
                    :class="{'voting--active':isVoting}"
                    title="Опрос"
                >
                    <svg class="new-post__vote-icon" width="17" height="18" viewBox="0 0 17 18" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 1C16.5523 1 17 1.44772 17 2L17 7C17 7.55229 16.5523 8 16 8L3 8L3 0.999999L16 1Z"
                              fill="#DBDEE9"/>
                        <path d="M9 10C9.55228 10 10 10.4477 10 11L10 16C10 16.5523 9.55228 17 9 17L3 17L3 10L9 10Z"
                              fill="#DBDEE9"/>
                        <rect x="2" width="18" height="2" transform="rotate(90 2 0)" fill="#DBDEE9"/>
                    </svg>
                </a>
                <label for="id_attach-img" class="new-post__attach-item new-post__attach-item--img" title="Изображение">
                    <svg class="new-post__img-icon" width="18" height="18" viewBox="0 0 18 18" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect width="18" height="18" rx="2" fill="#DBDEE9"/>
                        <circle cx="4.5" cy="4.5" r="1.5" fill="white"/>
                        <path
                            d="M5.5 10L2.29289 13.2071C2.10536 13.3946 2 13.649 2 13.9142V15C2 15.5523 2.44772 16 3 16H15C15.5523 16 16 15.5523 16 15V4.5L9 12L5.5 10Z"
                            fill="white"/>
                    </svg>
                </label>
                <a href="javascript:void(0)" class="new-post__attach-item hidden">
                    <svg class="new-post__video-icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.0155 3.50848H4.98449C2.23163 3.50848 0 5.74011 0 8.49298V15.507C0 18.2599 2.23163 20.4915 4.98449 20.4915H19.0155C21.7684 20.4915 24 18.2599 24 15.507V8.49298C24 5.74011 21.7684 3.50848 19.0155 3.50848ZM15.6445 12.3412L9.08177 15.4713C8.9069 15.5547 8.7049 15.4272 8.7049 15.2335V8.77775C8.7049 8.58127 8.91221 8.45393 9.08744 8.54275L15.6502 11.8684C15.8453 11.9673 15.8419 12.2471 15.6445 12.3412Z"
                            fill="#DBDEE9"/>
                    </svg>
                </a>
                <input type="file" id="id_attach-file" name="attach-file" hidden>
                <input type="file" ref="preview" id="id_attach-img" @change="addImgHandler"
                       name="attach-img" hidden>
            </div>
        </div>

        <div v-if="isVoting && !isAdvert" class="new-post__voting">
            <div @click="isVoting = false" class="new-post__voting-close"/>

            <ValidationProvider class="new-post__voting-group" tag="div" :rules="`required|min_length:5|max_length:50`" v-slot="{errors}" mode="eager">
                <label class="new-post__voting-label" for="vote_title">Тема опроса</label>
                <input type="text" id="vote_title" v-model="votes.title" class="new-post__voting-input new-post__voting-input--title" maxlength="50">
                <span class="v-error animated fadeInDown" v-if="errors.length">
                    {{errors[0]}}
                </span>
            </ValidationProvider>

            <div class="new-post__voting-list">
                <div v-for="(vote, index) in votes.list" :key="index" class="new-post__voting-item">
                    <ValidationProvider class="new-post__voting-group" :vid="`vote_${index}`" tag="div" :rules="`required|min_length:2|max_length:50`" v-slot="{errors}" mode="eager">
                        <label class="new-post__voting-label" :for="`vote_${index}`">{{index+1}} вариант ответа</label>
                        <div class="new-post__voting-wrap">
                            <input
                                type="text"
                                :id="`vote_${index}`"
                                class="new-post__voting-input"
                                v-model="vote.text"
                                maxlength="50"
                            />
                            <i
                                v-if="votes.list.length > 2"
                                class="new-post__voting-item-del"
                                href="javascript:void(0)"
                                @click="deleteVote(index)"
                            />
                        </div>
                        <span class="v-error animated fadeInDown" v-if="errors.length">
                            {{errors[0]}}
                        </span>
                    </ValidationProvider>
                    <!-- Удалить вариант ответа -->
                </div>
                <a
                    v-if="votes.list.length < 10"
                    class="new-post__voting-add"
                    href="javascript:void(0)"
                    @click="addVote"
                >+ Добавить вариант ответа</a>
            </div>
        </div>

        <div v-show="isFormActive || post_text.length" class="new-post__options">
            <div class="new-post__wrap">
                <button type="submit" class="v-btn new-post__submit" :disabled="isSending">
                    Опубликовать
                </button>
                <div class="new-post__access hidden">
                    <span class="new-post__scope"></span>
                    <a href="javascript:void(0)">Настроить доступ</a>
                </div>
            </div>

            <div v-if="wallSettings.advertPost.value" class="new-post__type">
                <toggle-button
                    class="new-post__switch"
                    color="#FBC04F"
                    :height="17"
                    :width="33"
                    :sync="true"
                    :disabled="!wallSettings.userPost.value && wallSettings.advertPost.value"
                    v-model="isAdvert"
                />
                <span class="new-post__type-title">Объявление</span>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import {mapState} from "vuex";
    import session from '@/api/session';

    export default {
        name: "NewPost",
        components: {
            Avatar : () => import('@/components/Avatar')
        },
        props: {
            afterCreate: {
                view: Function,
                required: true
            }
        },
        data() {
            return {
                post_title: "",
                post_text: '',
                imgSrc: "",
                imageList: [],
                file: null,
                votes: {
                    title: '',
                    list: [
                        {
                            text: ''
                        },
                        {
                            text: ''
                        }
                    ]
                },
                isVoting: false,
                isAdvert: false,
                isSending: false,
                isFormActive: false
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user',
                'limits',
                'wallSettings'
            ])
        },
        mounted() {
            this.isAdvert = (!this.wallSettings.userPost.value && this.wallSettings.advertPost.value);
        },
        methods: {
            addVote() {
                this.votes.list.push({
                    text: ''
                })
            },
            deleteVote(index1) {
                this.votes.list = this.votes.list.filter((vote, index)=> index1 !== index)
            },
            async onSubmit() {
                this.isSending = true;
                let isValid = await this.$refs.observer.validate();
                if (isValid) {
                    try {
                        let post = {
                            view: this.isAdvert ? "advert" : "post",
                            title: this.post_title,
                            title_poll: this.votes.title || "",
                            content: this.post_text,
                            votes: this.isVoting && !this.isAdvert ? this.votes.list : [],
                            files: [],
                            images: this.imageList.map(img => img.file)
                        };
                        let
                            config = {},
                            data = post;

                        this.$Progress.start();

                        if (this.imageList.length > 0) {
                            config['headers'] = {
                                'Content-Type': 'multipart/form-data'
                            };
                            // Добавляем данные из объекта в formData для отправки на сервер
                            let formData = new FormData();
                            for (let [key, value] of Object.entries(post)) {
                                //Если значение - массив, то переводим его значения в формат images[0]: file, images[1]: file...
                                if (typeof (value) === 'object' && key === 'votes') {
                                    for (let item of value) {
                                        formData.append(`${key}[]`, JSON.stringify(item));
                                    }
                                } else if (typeof (value) === 'object') {
                                    for (let item of value) {
                                        formData.append(`${key}[]`, item);
                                    }
                                } else {
                                    formData.append(key, value);
                                }
                            }
                            data = formData;
                        }

                        await session.post(`/api/v1/wall/`, data, config);
                        this.afterCreate();
                        this.$Progress.finish();

                        this.post_text = '';
                        this.post_title = '';
                        this.votes = {
                            title: '',
                            list: [
                                {
                                    text: ''
                                },
                                {
                                    text: ''
                                }
                            ]
                        };
                        this.isVoting = false;
                        this.isFormActive = false;
                        this.imageList = [];
                        this.$refs.observer.reset();
                    } catch(error) {
                        this.toastError('Произошла ошибка на сервере. Попробуйте позднее.');
                        console.error(error);
                        this.$Progress.finish();
                    }
                }
                this.isSending = false;
            },
            addImgHandler: function(e) {
                const file = e.target.files[0];
                const ALLOWED_TYPES = /\.(jpe?g|png)$/i;
                const { imageList } = this;

                // Очистка значения у поля для того, чтобы можно было загружать один и тот же файл подряд
                e.target.value = null;

                if (!ALLOWED_TYPES.test(file.name)) {
                    this.toastError('Допустимые форматы: png, jpg, jpeg');
                    return false;
                } else if (file.size > 5000000) {
                    this.toastError('Максимальный размер изображения: 5мб');
                    return false;
                } else if (this.imageList.length > 10) {
                    this.toastError('Максимум изображений: 10');
                    return false;
                } else {
                    this.imageList.push({
                        id: imageList.length ? [...imageList].pop().id + 1 : 1,
                        file,
                        src: URL.createObjectURL(file)
                    });
                }
            },
            onImgDelete: function(id) {
                this.imageList = this.imageList.filter(img => img.id !== id);
            },
            onBlurForm() {
                setTimeout(() => {
                    if (this.isVoting === false) {
                        this.isFormActive = false;
                    }
                }, 500)
            },
            toastError(text, timer = 3000) {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    title: text,
                    icon: "error",
                    showConfirmButton: false,
                    timer: timer,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    }
                });
            },
        },
    }
</script>

<style lang="sass">
    @import '#sass/v-style'

    .new-post
        display: flex
        flex-direction: column
        margin-bottom: 25px

        &__content
            display: flex
            flex-direction: column
            padding: 20px 30px
            margin-bottom: 0

        &__input
            display: flex
            width: 100%

        &__text-input
            width: 100%
            border: none
            resize: none
            outline: none
            padding:
                left: 15px
                top: 10px

            &::placeholder
                font-size: 1rem
                color: $text-gray
        &__title
            width: 100%
            border: none
            resize: none
            outline: none
            font:
                size: 18px
                weight: 600
            color: #000
            padding:
                left: 15px
                top: 10px
            &::placeholder
                font-size: 1.125rem
                font-weight: 600
                color: $text-gray
        &__inputs
            width: 100%
            display: flex
            flex-direction: column

        &__attach
            width: 100%
            display: flex
            justify-content: flex-end
            margin-top: 20px

            &-item
                cursor: pointer
                position: relative

                &:nth-last-child(n+2)
                    margin-left: 20px

                &:hover path,
                &:hover rect
                    fill: $gold
                    background: transparent

                .new-post__img-icon:hover path
                    fill: #fff
                &--vote.voting--active
                    & path,
                    & rect
                        fill: $gold
                        background: transparent
                    &::before
                        content: ''
                        z-index: 5
                        height: 10px
                        width: 10px
                        position: absolute
                        background: #fff
                        bottom: -25px
                        left: 5px
                        display: flex
                        margin: 0 auto
                        transform: rotate(45deg)
                        border:
                            top: 1px solid #DBDBDB
                            left: 1px solid #DBDBDB

        &__img

            &-list
                display: flex
                overflow-x: auto
                margin-top: 20px

            &-item
                height: 80px
                width: 80px
                min-height: 80px
                min-width: 80px
                background-repeat: no-repeat
                background-size: cover
                position: relative
                z-index: 1
                &:nth-child(n+2)
                    margin-left: 15px

            &-delete
                height: 16px
                width: 16px
                position: absolute
                top: 0
                right: 0
                background-color: rgba(0, 0, 0, .7)
                background-image: $times-icon
                background-repeat: no-repeat
                background-position: center
                z-index: 2
                cursor: pointer

        &__options
            display: flex
            justify-content: space-between
            margin-top: 25px

        &__wrap
            display: flex

        &__type
            display: flex
            align-items: center
            &-title
                font-size: 1rem
                color: #000
        &__switch
            margin:
                right: 10px
                bottom: 0
        &__voting
            width: 100%
            padding: 30px
            background: #fff
            border-top: 1px solid #DBDBDB
            position: relative
            &-wrap
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
            &-close
                background-image: $plus-icon
                background-repeat: no-repeat
                background-position: center
                background-size: contain
                height: 15px
                width: 15px
                transform: rotate(45deg)
                position: absolute
                right: 20px
                top: 20px
                cursor: pointer
            &-group
                display: flex
                flex-direction: column
                width: 100%
            &-label
                color: #aaa
                font-weight: normal
            &-input
                border: 1px solid #DBDBDB
                outline: none
                height: 40px
                padding-left: 15px
                width: 100%
                &--title
                    width: 75%
            &-item
                display: flex
                align-items: center
                width: 100%
                position: relative
                &:nth-child(n+2)
                    margin-top: 10px
                &-del
                    background-image: $plus-icon
                    background-repeat: no-repeat
                    background-position: center
                    background-size: contain
                    height: 15px
                    width: 15px
                    transform: rotate(45deg)
                    position: absolute
                    right: -25px
                    cursor: pointer
            &-list
                width: 50%
                margin-top: 20px
            &-add
                display: flex
                align-items: center
                justify-content: center
                width: 100%
                border: 1px dashed #DBDBDB
                border-radius: 4px
                height: 40px
                color: #000
                font-size: 1rem
                margin-top: 30px

</style>
